import React, { useRef, useEffect } from 'react';
import '../styles/styles.scss';
import { Seo } from '../components/Seo';
import Footer from '../components/Footer';
import { blinkingEyesAnimation } from '../animations/blinking-eyes';
import { Link } from 'gatsby';
import { Button } from '../components/Button';

const NotFoundPage = () => {
  const animRef = useRef(null);

  useEffect(() => {
    let lottie: any = null;
    const dynamicLottie = async () => {
      lottie = await import('lottie-web');
      const params = {
        name: 'blinkingEyes404',
        container: animRef.current!,
        loop: true,
        autoplay: true,
        animationData: blinkingEyesAnimation,
      };
      lottie.loadAnimation(params);
    };

    dynamicLottie();
    return () => lottie?.destroy('blinkingEyes404');
  }, []);

  return (
    <>
      <main id='wip'>
        <div className='wrapper'>
          <div className='lottie-container' ref={animRef}></div>
          <h1>Hier ist's leer</h1>
          <Link to='/'>
            <Button color='primary'>Zurück zur Homepage</Button>
          </Link>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default NotFoundPage;

export const Head = () => <Seo title='Not found' />;
