export const blinkingEyesAnimation = {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 1.1.0', a: '', k: '', d: '', tc: '' },
  fr: 29.9700012207031,
  ip: 0,
  op: 300.00001221925,
  w: 1400,
  h: 1400,
  nm: 'Eye Comp',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          nm: 'Eye Blink Matte',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [180.223, 249.401, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 134.48, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 300.00001221925,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Eye Blink',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [181.5, 179.5, 0],
                  to: [0, -43.833, 0],
                  ti: [0, 43.833, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.333, y: 0.333 },
                  t: 12,
                  s: [181.5, -83.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 31,
                  s: [181.5, -83.5, 0],
                  to: [0, 43.833, 0],
                  ti: [0, -43.833, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 37,
                  s: [181.5, 179.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 42,
                  s: [181.5, 179.5, 0],
                  to: [0, -43.833, 0],
                  ti: [0, 43.833, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 48,
                  s: [181.5, -83.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 208,
                  s: [181.5, -83.5, 0],
                  to: [0, 43.833, 0],
                  ti: [0, -43.833, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 218,
                  s: [181.5, 179.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 227,
                  s: [181.5, 179.5, 0],
                  to: [0, -43.833, 0],
                  ti: [0, 43.833, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.167, y: 0.167 },
                  t: 240,
                  s: [181.5, -83.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 287,
                  s: [181.5, -83.5, 0],
                  to: [0, 43.833, 0],
                  ti: [0, -43.833, 0],
                },
                { t: 300.00001221925, s: [181.5, 179.5, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-0.357, -96.133],
                              [84.643, 149.867],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-91.357, 147.867],
                              [1.643, -109.134],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.333, y: 0 },
                        t: 12,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0.643, -52.133],
                              [92.643, -111.133],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-87.357, -112.133],
                              [1.643, -109.133],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.967, y: 0 },
                        t: 31,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0.643, -52.133],
                              [92.643, -111.133],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-87.357, -112.133],
                              [1.643, -109.133],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 37,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-0.357, -96.133],
                              [84.643, 149.867],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-91.357, 147.867],
                              [1.643, -109.134],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 42,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-0.357, -96.133],
                              [84.643, 149.867],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-91.357, 147.867],
                              [1.643, -109.134],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 48,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0.643, -52.133],
                              [92.643, -111.133],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-87.357, -112.133],
                              [1.643, -109.133],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 208,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0.643, -52.133],
                              [92.643, -111.133],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-87.357, -112.133],
                              [1.643, -109.133],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 218,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-0.357, -96.133],
                              [84.643, 149.867],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-91.357, 147.867],
                              [1.643, -109.134],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 227,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-0.357, -96.133],
                              [84.643, 149.867],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-91.357, 147.867],
                              [1.643, -109.134],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0 },
                        t: 240,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0.643, -52.133],
                              [92.643, -111.133],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-87.357, -112.133],
                              [1.643, -109.133],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 287,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0.643, -52.133],
                              [92.643, -111.133],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-87.357, -112.133],
                              [1.643, -109.133],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 300.00001221925,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-0.357, -96.133],
                              [84.643, 149.867],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-91.357, 147.867],
                              [1.643, -109.134],
                              [0, 0],
                            ],
                            v: [
                              [181.5, -244.5],
                              [181.5, 244.5],
                              [-181.5, 244.5],
                              [-181.5, -244.5],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0.357, -61.867], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 300.00001221925,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Pupil',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [183.223, 247.401, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 134.48, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [15, 15], ix: 2 },
                  p: {
                    a: 0,
                    k: [0, 0],
                    ix: 3,
                    x: "var $bm_rt;\n$bm_rt = content('Ellipse 1').content('Ellipse Path 1').position;",
                  },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.981259574142, 0.981259574142, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [10.742, -9.949], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [70.318, 54.069], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [75, 75], ix: 2 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.442, y: 1 },
                        t: 61,
                        s: [0, 0],
                        to: [16.667, -8.333],
                        ti: [-16.667, 8.333],
                      },
                      {
                        i: { x: 0.667, y: 0.667 },
                        o: { x: 0.167, y: 0.167 },
                        t: 76,
                        s: [100, -50],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0 },
                        t: 92,
                        s: [100, -50],
                        to: [-36.667, 1.667],
                        ti: [36.667, -1.667],
                      },
                      {
                        i: { x: 0.667, y: 0.667 },
                        o: { x: 0.167, y: 0.167 },
                        t: 110,
                        s: [-120, -40],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0 },
                        t: 127,
                        s: [-120, -40],
                        to: [0, 19.167],
                        ti: [0, -19.167],
                      },
                      {
                        i: { x: 0.667, y: 0.667 },
                        o: { x: 0.167, y: 0.167 },
                        t: 147,
                        s: [-120, 75],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0 },
                        t: 178,
                        s: [-120, 75],
                        to: [20, -12.5],
                        ti: [-20, 12.5],
                      },
                      { t: 204.00000830909, s: [0, 0] },
                    ],
                    ix: 3,
                  },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [70.318, 54.069], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 300.00001221925,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Eye White',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [180.223, 249.401, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 134.48, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [300, 325], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 300.00001221925,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Left Eye',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [550.804, 660.748, 0], ix: 2 },
        a: { a: 0, k: [181.5, 244.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 363,
      h: 489,
      ip: 1.00000004073083,
      op: 300.00001221925,
      st: 1.00000004073083,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'Right Eye',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [879.907, 660.748, 0], ix: 2 },
        a: { a: 0, k: [181.5, 244.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 363,
      h: 489,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
