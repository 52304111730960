import React from 'react';

type ButtonProps = {
  label?: string;
  type?: 'button' | 'submit' | 'reset';
  color?: 'primary' | 'secondary' | 'gradient';
  disabled?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
};

export const Button = (props: ButtonProps) => {
  const classNames = ['button'];
  props.loading && classNames.push('is-loading');
  props.color && classNames.push(`is-${props.color}`);

  return (
    <button
      type={props.type}
      className={classNames.join(' ')}
      disabled={props.disabled || props.loading}
    >
      {props.children || props.label}
    </button>
  );
};

export default Button;
